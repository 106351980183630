import React, { Component, Fragment } from 'react'
import { ABanner, Go } from 'shared/components/common'
import flechaApp from 'img/vectores/flechaApp.svg'
import { Col, Container, Row } from 'reactstrap'
import t1 from 'img/tutoriales/registro/t1.png'
import t2 from 'img/tutoriales/registro/t2.png'
import t3 from 'img/tutoriales/registro/t3.png'
import t4 from 'img/tutoriales/registro/t4.png'
import t5 from 'img/tutoriales/registro/t5.png'
import t6 from 'img/tutoriales/registro/t6.png'
import t7 from 'img/tutoriales/registro/t7.png'
import './signUpTutorial.css'
export class SignUpTutorial extends Component<any> {
  render() {
    return (
      <Fragment>
        <ABanner
          title="Como registrarme en AbordoMovil"
          titleClassName="big-white"
          titleSize={9}
          content={<img src={flechaApp} className="img-fluid" />}
        />
        <Container className="pad ">
          <Row>
            <Col xs={12}>
              <h2 className="big-blue">AbordoMovil</h2>

              <br />
            </Col>
          </Row>

          <Row>
            <Col>
              <h4 className="big-blue">Paso 1.</h4>
              <p>
                Ejecuta tu aplicación AbordoMovil,
                <p>en la parte inferior presiona "Crea tu cuenta de Abordo".</p>
              </p>
            </Col>
            <Col>
              <img src={t1} className="img-tutorial" />
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="big-blue">Paso 2.</h4>
              <p>
                Llena el formulario con los datos que se requieren para crear tu cuenta Abordo.
                <p>Luego presiona el botón "CREAR CUENTA".</p>{' '}
              </p>
            </Col>
            <Col>
              <img src={t2} className="img-tutorial" />
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="big-blue">Paso 3.</h4>
              <p>Para iniciar sesíon por primera vez es necesario confirmar su cuenta de correo.</p>
            </Col>
            <Col>
              <img src={t3} className="img-tutorial" />
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="big-blue">Paso 4.</h4>
              <p>
                Si no encuentras el correo de confirmación, lo puedes encontrar en <strong>carpeta de spam, otros o redes sociales.</strong>
              </p>
            </Col>
            <Col>
              <img src={t4} className="img-tutorial" />
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="big-blue">Paso 5.</h4>
              <p>
                Antes de abrir el correo verifique que el correo remitente sea <strong>noreply@allabordo.com</strong>{' '}
              </p>
            </Col>
            <Col>
              <img src={t5} className="img-tutorial" />
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="big-blue">Paso 6.</h4>
              <p>
                Para confirmar el correo presione en el botón de <strong>"Confirmar aqui" </strong>
                <p>
                  o presione el <strong>link</strong> que se encuentra debajo. Este lo enlaza al sitio oficial de abordo.
                </p>
              </p>
            </Col>
            <Col>
              <img src={t6} className="img-tutorial" />
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="big-blue">Paso 7.</h4>
              <p>
                Si realizamos los pasos anteriores correctamente, tendremos un mensaje como el de la imagen y su cuenta estará{' '}
                <strong>verificada</strong> y <strong>lista para iniciar sesión</strong>.
              </p>
            </Col>
            <Col>
              <img src={t7} className="img-tutorial" />
            </Col>
          </Row>
          <Row>
            <Go to="/baseconocimientos">
              {' '}
              <h2 className="big-blue">Más tutoriales aquí.</h2>
            </Go>
          </Row>
        </Container>
      </Fragment>
    )
  }
}

export default SignUpTutorial
